<template>
  <div class="pc-exam-black-board">
    <div class="pc-exam-black-board__main">
      <div class="pc-exam-black-board__board">
        <div class="pc-exam-black-board__top" />
        <div class="pc-exam-black-board__middle">
          <div class="pc-exam-black-board__inner">
            <div
              :style="{transform: `translateX(${currentIndex * -100}%)`}"
              name="slide"
              class="pc-exam-black-board__slider-list"
            >
              <div
                v-for="(currentDataItem, i) in list"
                :key="i"
                class="pc-exam-black-board__slider"
              >
                <div class="pc-exam-black-board__question">
                  {{ i + 1 }}/{{ list.length }}&nbsp;&nbsp;{{ currentDataItem.title }}
                </div>
                <div class="pc-exam-black-board__answer-list">
                  <div
                    v-for="(item, index) in currentDataItem.answerOptions"
                    :key="index"
                    @click="onOptionClick(currentDataItem, index)"
                    class="pc-exam-black-board__answer-item"
                  >
                    <div
                      :class="{'answer-yes': currentDataItem.choosedIndex === index && item.isCorrect,
                               'answer-no': currentDataItem.choosedIndex === index && !item.isCorrect}"
                      class="answer-icon"
                    />
                    <div class="answer-text">
                      {{ item.text }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pc-exam-black-board__bottom" />

        <div class="pc-exam-black-board__pic-left">
          <div class="doctor-shan" />
          <div
            v-if="showWrongTips"
            class="doctor-tips"
          >
            呀~再想想？
          </div>
          <div
            v-if="showCorrectTips"
            class="doctor-tips"
          >
            哇！答对了~！
          </div>
          <div
            v-if="showWrongTips"
            class="pic-shan shan-shork"
          />
          <div
            v-else
            class="pic-shan shan-smile"
          />
        </div>
      </div>
    </div>
    <div class="pc-exam-black-board__button-wrapper">
      <Button
        v-if="currentIndex > 0"
        @click="showPrev"
        size="l"
        class="pc-exam-black-board__button"
      >
        上一题
      </Button>
      <Button
        v-if="currentIndex <= answeredIndex"
        @click="showNext"
        size="l"
        class="pc-exam-black-board__button"
      >
        下一题
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/ui/button';
import jsbridge from '@/jsbridge';
import { logger } from '@/util/logger';

export default {
  name: 'PcExamBlackBoard',
  components: {
    Button,
  },
  inject: ['reportData'],
  props: {
    list: {
      type: Array,
      default: () => ['1', '2', '3', '4'],
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showWrongTips: false,
      showCorrectTips: false,
      timer: null,
      answeredIndex: -1, // 保存答过的历史
      transitionLoading: false, // 动画进行中
      correctAnswerTryTimes: 0, // 答题成功尝试的次数，用于数据上报
      staytimeStart: (new Date()).getTime(), // 用于上报问题停留时长
    };
  },
  computed: {
    currentData() {
      return this.list[this.currentIndex] || {};
    },
    isApp: state => state.isApp,
  },
  methods: {
    onOptionClick(currentData, index) {
      if (this.transitionLoading || !currentData.answerOptions || !currentData.answerOptions[index]) return;
      clearTimeout(this.timer);
      // eslint-disable-next-line no-param-reassign
      currentData.choosedIndex = index;
      if (currentData.answerOptions[index].isCorrect) {
        this.showWrongTips = false;
        this.showCorrectTips = true;
        this.timer = setTimeout(() => {
          if (this.answeredIndex < this.currentIndex) {
            this.answeredIndex = this.currentIndex;
          }
          this.showNext();
        }, 1000);
      } else {
        this.correctAnswerTryTimes += 1; // 记录一次
        this.answeredIndex = this.currentIndex - 1;
        this.showWrongTips = true;
        this.showCorrectTips = false;
      }
    },
    showNext() {
      this.reportData({
        moduleid: 1,
        eventtype: 6,
        extendid: 2,
        contentid: this.currentData.id,
        ext1: this.correctAnswerTryTimes + 1, // 上报点击几次后成功
      }, '答题成功上报');
      this.reportStayTimeCall();
      clearTimeout(this.timer);
      this.showWrongTips = false;
      this.showCorrectTips = false;
      this.transitionLoading = true;
      setTimeout(() => {
        this.transitionLoading = false;
      }, 1000);
      // console.log('currentData', this.currentData);
      this.correctAnswerTryTimes = 0;
      this.$emit('next');
    },
    showPrev() {
      this.reportData({
        moduleid: 1,
        eventtype: 3,
        extendid: 1,
        contentid: this.currentData.id,
      }, '上一题按钮点击');
      this.reportStayTimeCall();
      this.correctAnswerTryTimes = 0;
      clearTimeout(this.timer);
      this.showWrongTips = false;
      this.showCorrectTips = false;
      this.$emit('prev');
    },
    registerReportEvent() {
      if (this.isApp) {
        this.staytimeStart = (new Date()).getTime();
        jsbridge.ui.setOnVisibilityChange({
          callback: (res) => {
            logger.info('page setOnVisibilityChange', res);
            if (res.result === 0) {
              if (!res.data.visibilityState) {
                this.reportStayTimeCall();
              } else {
                this.staytimeStart = (new Date()).getTime();
              }
            }
          },
        });
      }
    },
    reportStayTimeCall() {
      const now = (new Date()).getTime();
      const staytime = Math.round((now - this.staytimeStart) / 1000);
      this.staytimeStart = now;
      // console.log({
      //   moduleid: 1,
      //   eventtype: 6,
      //   extendid: 3,
      //   staytime,
      // });
      if (staytime > 0 && staytime < 3600) {
        this.reportData({
          moduleid: 1,
          eventtype: 6,
          extendid: 3,
          staytime,
          contentid: this.currentData.id,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.pc-exam-black-board {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, #F9F9F9 0%, #FFFFFF 20%);
  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  &__board {
    position: relative;
    flex: 1;
    margin: 15px 12px 0;
    // padding: 38px 0px 93px;
    // background: url('../../img/black-board.png') center/100% 100% no-repeat;
    padding-bottom: 73 / 702 * 100%;
    max-height: 90%;
  }
  &__middle {
    background: url('../../img/black-board-middle.jpg') center/100% 100% no-repeat;
    padding: 0 13px;
  }
  &__inner {
    min-height: 350px;
    max-height: 80%;
    overflow: hidden;
  }
  &__top {
    padding-bottom: 76 / 702 * 100%;
    background: url('../../img/black-board-top.png') center/100% 100% no-repeat;
  }
  &__bottom {
    width: 100%;
    position: absolute;
    padding-bottom: 73 / 702 * 100%;
    background: url('../../img/black-board-bottom.jpg') center/100% 100% no-repeat;
  }

  &__question {
    font-size: 20px;
    color: #ffffff;
  }
  &__answer-list {
    min-height: 250px;
  }
  &__answer-item {
    margin-top: 15px;
    padding: 9px 20px 10px 9px;
    border-radius: 15px;
    min-height: 50px;
    box-sizing: border-box;
    background-color: #F2F2F2;
    display: flex;
    align-items: center;
    .answer-icon {
      width: 32px;
      height: 32px;
      flex: 0 0 32px;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 4px;
      &.answer-yes {
        background-image: url('../../img/icon-yes.png');
      }
      &.answer-no {
        background-image: url('../../img/icon-no.png');
      }
    }
    .answer-text {
      line-height: 20px;
      font-size: 14px;
      color: #303133;
    }
  }
  &__pic-left {
    position: relative;
    height: 0px;
    top: 10%;
    width: 100%;
    flex: 0 1 auto;
    .doctor-shan {
      position: absolute;
      width: 175px;
      height: 205px;
      top: -70px;
      left: -100px;
      background: url('../../img/doctor-shan.png') center/100% 100% no-repeat;
      animation: updown 3s linear infinite;
    }
    .doctor-tips {
      position: absolute;
      box-sizing: border-box;
      width: 143px;
      height: 65px;
      left: 80px;
      top: -26px;
      padding-top: 26px;
      text-align: center;
      background: url('../../img/bubble-tips.png') center/100% 100% no-repeat;
      color: #FFFFFF;
      font-size: 15px;
    }
    .pic-shan {
      position: absolute;
      right: -70px;
      top: -90px;
      width: 123px;
      height: 97px;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    .shan-shork {
      background-image: url('../../img/shan-shork.png');
    }
    .shan-smile {
      background-image: url('../../img/shan-smile.png');
    }
  }
  &__button-wrapper {
    padding: 0 18px;
    z-index: 10;
    display: flex;
    flex: 0.1 0 auto;
    position: absolute;
    width: 100%;
    bottom: 20px;
    align-items: center;
    box-sizing: border-box;
  }
  &__button {
    flex: 1;
    &:not(:first-child) {
      margin-left: 14px;
    }
  }

  &__slider-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    // white-space: nowrap;
    font-size: 0;
    transition: transform .5s linear;
    width: auto;
    max-height: 100%;
  }
  &__slider {
    flex: 0 0 100%;
    vertical-align: top;
    width: 100%;
    // display: inline-block;
    padding: 0 20px;
    box-sizing: border-box;
    white-space: normal;
    max-height: 500px;
    overflow-y: auto;
  }

  @keyframes updown {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(3%);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-3%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
</style>
