import { gameplusApi } from '@/util/http';

export function getExamQuestionList() {
  return gameplusApi.request({
    url: '/community.ExamSrv/GetExamQuestionList',

  });
}

export function sendExamResult(params) {
  return gameplusApi.request({
    url: '/community.ExamSrv/ReportExamResult',
    data: params,
  });
}
