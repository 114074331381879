<template>
  <div class="pc-exam-end">
    <div class="pc-exam-end__content">
      <div class="pc-exam-end__title">
        太棒啦，通过礼仪考试啦！
      </div>
      <div class="pc-exam-end__text">
        你已经完成了闪现一下礼仪必修课<br>
        相信你一定会好好遵守社区规则哦
      </div>
    </div>
    <div class="pc-exam-end__bg">
      <div class="pc-exam-end__bg-img" />
      <div class="pc-exam-end__bg-tips" />
    </div>
    <div class="pc-exam-end__button">
      <Button
        @click="$emit('next')"
        type="primary"
        size="l"
        width="100%"
      >
        去发言
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/ui/button';

export default {
  name: 'PcExamEnd',
  components: {
    Button,
  },
  data() {
    return {
      text: '亲爱的玩家，这次的礼仪学习能帮助大家了解什么在闪现一下是提倡的，什么是不提倡的，完成后就发布帖子和提问啦，我们等你来发声~',
    };
  },
};
</script>

<style lang="scss">
.pc-exam-end {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__bg {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
    &-img {
      width: 100%;
      height: 0;
      padding-top: (724 / 800 * 100%);
      background: url('../../img/cover.png') center / 100% 100% no-repeat;
      animation: updown 3s linear infinite;
    }
    &-tips {
      position: absolute;
      top: 105px;
      left: 110px;
      width: 100px;
      height: 65px;
      background: url('../../img/tips-success.png') center / 100% 100% no-repeat;
      // animation: updown 2s linear infinite 1s;
    }
  }
  &__content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 21px 45px;
  }
  &__title {
    font-size: 20px;
    color: #303133;
    text-align: center;
  }
  &__text {
    margin-top: 15px;
    font-size: 12px;
    line-height: 1.5;
    color: #303133;
    text-align: center;
    color: #999999;
  }
  &__button {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 10px 45px 25px;
  }

  @keyframes updown {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(3%);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-3%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
</style>
