<template>
  <div class="pc-exam-cover">
    <div class="pc-exam-cover__bg">
      <div class="pc-exam-cover__bg-img" />
      <div class="pc-exam-cover__bg-tips" />
    </div>
    <div class="pc-exam-cover__content">
      <div class="pc-exam-cover__text">
        {{ text }}
      </div>
    </div>
    <div class="pc-exam-cover__button">
      <Button
        @click="$emit('next')"
        type="primary"
        size="l"
        width="100%"
      >
        开始学习
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/ui/button';

export default {
  name: 'PcExamCover',
  components: {
    Button,
  },
  data() {
    return {
      text: 'Hi，欢迎来到闪现一下礼仪学习小课堂！作为一名优秀的闪友，需要了解在闪现一下的基本行为准则哦~本次小课堂只有5道题，完成后就能发言啦！一起来维护闪现一下积极、友好的氛围吧！',
    };
  },
};
</script>

<style lang="scss">
.pc-exam-cover {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__bg {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
    &-img {
      width: 100%;
      height: 0;
      padding-top: (724 / 800 * 100%);
      background: url('../../img/cover.png') center / 100% 100% no-repeat;
      animation: updown 3s linear infinite;
    }
    &-tips {
      position: absolute;
      top: 90px;
      left: 110px;
      width: 100px;
      height: 65px;
      background: url('../../img/tips-start.png') center / 100% 100% no-repeat;
      // animation: updown 2s linear infinite 1s;
    }
  }
  &__content {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    // justify-content: center;
    padding: 21px 45px;
  }
  &__text {
    font-size: 15px;
    line-height: 1.5;
    color: #303133;
  }
  &__button {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 10px 45px 25px;
  }
  @keyframes updown {
    0% {
      transform: translateY(0);
    }
    25% {
      transform: translateY(3%);
    }
    50% {
      transform: translateY(0);
    }
    75% {
      transform: translateY(-3%);
    }
    100% {
      transform: translateY(0);
    }
  }
}
</style>
