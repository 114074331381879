<template>
  <div
    :class="{'show-header': showHeader}"
    class="p-exam"
  >
    <PostHeader
      v-if="showHeader"
      :auto-back="false"
      :menu-status="false"
      @back="onPageBackHandler"
    >
      <span class="p-exam__header-title">
        闪现一下礼仪学习
      </span>
    </PostHeader>
    <div class="p-exam__container">
      <!-- <Button size="xl"
      class="p-exam__button"
      type="primary"
      @click="sendNoticeSuc"
    >
      回答完成
    </Button>
    <Button size="xl"
      class="p-exam__button"
      @click="sendNoticeFail"
    >
      回答未完成
    </Button> -->

      <transition name="slide">
        <ExamCover
          v-if="stage===1"
          @next="stage += 1"
        />
      </transition>
      <transition
        name="slide"
        class="p-exam__full-page"
      >
        <ExamBlackBoard
          v-if="stage===2"
          :list="questionList"
          :current-index="currentQuestionIndex"
          @prev="handlerQuestionPrev"
          @next="handlerQuestionNext"
        />
      </transition>
      <transition
        name="slide"
        class="p-exam__full-page"
      >
        <ExamEnd
          v-if="stage===3"
          @next="onPublish"
        />
      </transition>
    </div>

    <!-- 弹窗提示 -->
    <Dialog
      v-model="exitDialogShow"
      :confirm="true"
      @confirm="onPopback()"
      @cancel="exitDialogShow = false"
      confirm-text="确认退出"
      cancel-text="继续答题"
    >
      <div class="p-exam__dialog-content">
        还没有完成答题，通过后才可以参加互动，确定要退出吗？
      </div>
    </Dialog>

    <!-- 预加载图片，不展示 -->
    <div class="preload-images">
      <img src="./img/black-board-top.png">
      <img src="./img/black-board-middle.jpg">
      <img src="./img/black-board-bottom.jpg">
      <img src="./img/doctor-shan.png">
      <img src="./img/shan-shork.png">
      <img src="./img/shan-smile.png">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Dialog from '@/ui/dialog/index.vue';
import { getExamQuestionList, sendExamResult } from '@/api/exam';
import { isBitTrue, randomSort } from '@/util/util';
import jsbridge, { send as noticeSend, popBack } from '@/jsbridge';
import PostHeader from '@/component/post-header/index.vue';
import { logger } from '@/util/logger';
import reportDataMixin from '@/plugin/mixin/reportData';
import ExamCover from './component/exam-cover/index.vue';
import ExamEnd from './component/exam-end/index.vue';
import ExamBlackBoard from './component/exam-black-board/index.vue';

export default {
  name: 'PBindPhone',
  components: {
    // PageHeader,
    Dialog,
    PostHeader,
    ExamCover,
    ExamBlackBoard,
    ExamEnd,
  },
  mixins: [reportDataMixin],
  data() {
    return {
      stage: 1,
      questionList: [],
      currentQuestionIndex: 0,
      exitDialogShow: false,
      reportDataParams: { // 由mixin使用，上报公共参数
        pageid: 1509,
      },
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      isAndroid: state => state.isAndroid,
      isIOS: state => state.isIOS,
      userInfo: state => state.user.userInfo,
    }),
    showHeader() {
      const { _gcwv: wv } = this.$route.query;
      return (this.isApp && wv && isBitTrue(Number(wv), 1));
    },
  },
  watch: {
    //
  },
  beforeCreate() {
    window.addEventListener('touchmove', (event) => {
      event.preventDefault();
    }, false);
  },
  mounted() {
    logger.info('userExam mounted', window.location.href);
    // 页面曝光pv
    this.reportData({
      moduleid: 1,
      eventtype: 1,
      extendid: 1,
    }, '答题页面曝光');
    this.registerReportEvent();
    this.$store.dispatch('user/getUserInfo')
      .then(() => {
        if (this.userInfo) {
          this.initData();
        }
      })
      .catch((err) => {
        logger.error('getUserInfo error', err);
      });
    jsbridge.core.call('ui/setBackListener', {
      action: 1, // 增加监听
      callback: () => {
        this.$emit('change', false);
        this.onPageBackHandler();
      },
    });
  },
  beforeDestroy() {
    clearTimeout(this.codeClockTimer);
    jsbridge.core.call('ui/setBackListener', {
      action: 0, // 移除监听
    });
  },
  methods: {
    initData() {
      return getExamQuestionList().then((res) => {
        if (res.questions.length === 0) {
          this.stage = 3;
        }
        const list = res.questions.map((item) => {
          let optionList = item.answerOptions.map((option, index) => ({
            isCorrect: index === 0, // 是否正确
            text: option,
          }));
          optionList = randomSort(optionList);
          return {
            id: item.id,
            title: item.title,
            answerOptions: optionList,
            choosedIndex: -1,
          };
        });
        this.questionList = list;
      })
        .catch((err) => {
          logger.error('initData error', err);
          this.$router.push('/error/common');
        });
    },
    sendNoticeSuc() {
      noticeSend({
        noticeId: 'examStatus',
        noticeEvent: 'pass',
        noticeParams: '',
      });
    },
    sendNoticeFail() {
      noticeSend({
        noticeId: 'examStatus',
        noticeEvent: 'unpass',
        noticeParams: '',
      });
    },
    sendNotice(event) {
      noticeSend({
        noticeId: 'examStatus',
        noticeEvent: event,
        noticeParams: '',
      });
    },
    onPageBackHandler() {
      this.reportData({
        moduleid: 1,
        eventtype: 3,
        extendid: 2,
        ext2: this.currentQuestionIndex + 1,
      }, '退出回答点击');
      if (this.stage === 3) {
        popBack();
      } else {
        this.exitDialogShow = true;
      }
    },
    onPopback() {
      this.exitDialogShow = false;
      popBack();
    },
    handlerQuestionNext() {
      if (this.questionList && this.currentQuestionIndex < this.questionList.length - 1) {
        this.currentQuestionIndex += 1;
      } else if (this.currentQuestionIndex === this.questionList.length - 1) {
        this.sendExamResult();
      }
    },
    handlerQuestionPrev() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex -= 1;
      }
    },
    sendExamResult() {
      const questionIds = [];
      for (let i = 0; i < this.questionList.length; i++) {
        const item = this.questionList[i];
        if (item.choosedIndex > -1 && item.answerOptions[item.choosedIndex].isCorrect) {
          questionIds.push(item.id);
        } else {
          this.currentQuestionIndex = i;
        }
      }
      if (questionIds.length === this.questionList.length) {
        sendExamResult({
          doneTime: Math.floor((new Date()).getTime() / 1000),
          questionIds,
        }).then(() => {
          // 答题完成
          this.stage = 3;
          this.sendNotice('pass');
        });
      }
    },
    onPublish() {
      this.sendNotice('publish');
      popBack();
    },
    // 停留时长，由reportDataMixin上报
    reportStayTime(staytime) {
      this.reportData({
        moduleid: 1,
        eventtype: 6,
        extendid: 1,
        staytime,
      }, {
        description: '停留时间上报',
        saveToLocal: true, // 存在本地
      });
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
